import React, {useMemo} from 'react'
import IconSharePortfolio from '@svg/common/ic_btn_share_v2.svg'
import {useTranslation} from 'react-i18next'
import Text from '@component/Text/Text'

interface IProps {
    className?: string
    containerClassName?: string
    onClick?: () => void
    text?: string
    size?: 'small' | 'medium'
}

const SharePortfolioButton: React.FC<IProps> = ({className, containerClassName, onClick, text, size = 'medium'}) => {
    const {t} = useTranslation()

    const getSizeStyle = useMemo(() => {
        switch (size) {
            case 'small':
                return 'py-[7px] px-[15px]'
            case 'medium':
                return 'py-[10px] px-[20px] '
        }
    }, [size])
    return (
        <div className={`w-full mt-[10px] ${containerClassName}`}>
            <button
                onClick={onClick}
                className={`bg-white dark:bg-bg_dark_white03 gap-x-[5px] ${getSizeStyle} ring-[1px] ring-gray06 dark:ring-dark_gray06 rounded-[20px] mx-auto justify-center flex items-center hover:bg-gray09 dark:hover:bg-dark_gray09 active:bg-gray07 dark:active:bg-dark_gray07 ${className}`}>
                <IconSharePortfolio className={'w-[12px] fill-primary dark:fill-dark_primary'} />
                <Text
                    enablePreWhiteSpace={false}
                    className={'text-body3 whitespace-nowrap text-gray01 dark:text-dark_gray01'}>
                    {text}
                </Text>
            </button>
        </div>
    )
}

export default SharePortfolioButton
