import React, {useImperativeHandle, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import WizButton from '@component/buttons/WizButton'
import WizContentModal from '@component/modal/WizContentModal'
import useCopy from '@hook/useCopy'
import {useRouter} from 'next/router'
import {toast} from 'react-toastify'
import WizToastMessage from '@component/snackbar/WizToastMessage'
import SharePortfolioModal from '@feature/portfolio/components/SharePortfolioModal/SharePortfolioModal'
import {createLog} from '@util/logs'

interface IProps {
    onCloseCallback?: () => void
    children?: React.ReactNode
}

const SharePortfolioOptionModal = ({onCloseCallback, children}: IProps, ref: any) => {
    const {t} = useTranslation()
    const router = useRouter()
    const {copyToClipboard} = useCopy()
    const modalRef = useRef<any>()
    const sharePortfolioModalRef = useRef<any>()
    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
    }))

    return (
        <>
            <WizContentModal
                size={'400'}
                title={t('modal.sharePortfolio.title')}
                className={'sm:px-[20px]'}
                panelClassName={'sm:max-w-[400px] sm:flex-none sm:!rounded-[5px]'}
                onCloseCallback={() => {
                    onCloseCallback && onCloseCallback()
                }}
                ref={modalRef}>
                <div className={'mt-[30px] flex flex-col gap-[10px] pb-[20px]'}>
                    <WizButton
                        textClassName={'text-btn'}
                        onClick={() => {
                            modalRef?.current?.close()
                            createLog('event', 'portfolio_sidebar_open_share_portfolio_modal')
                            sharePortfolioModalRef?.current?.show()
                        }}
                        text={t('modal.sharePortfolio.card')}
                        buttonType={'border'}
                    />
                    <WizButton
                        textClassName={'text-btn'}
                        onClick={() => {
                            copyToClipboard(`${window.location.origin}/${router.locale}${router?.asPath}`)
                            createLog('event', 'portfolio_sidebar_share_portfolio_copy_link')
                            toast(<WizToastMessage text={t('common.copied')} />)
                        }}
                        text={t('modal.sharePortfolio.link')}
                        buttonType={'border'}
                    />
                </div>
            </WizContentModal>
            <SharePortfolioModal ref={sharePortfolioModalRef} />
        </>
    )
}

export default React.forwardRef(SharePortfolioOptionModal)
