import React from 'react'
import Text from '@component/Text/Text'

interface IProps {
    text?: string
    className?: string
    textColorClassName?: string
    textSizeClassName?: string
    bgColorClassName?: string
    bgHeightClassName?: string
}

const TextHighlight: React.FC<IProps> = ({
    text,
    className,
    textColorClassName = 'text-gray01 dark:text-dark_gray01',
    textSizeClassName = 'text-h1',
    bgColorClassName = 'bg-primary dark:bg-dark_primary',
    bgHeightClassName = 'h-[15px] bottom-2',
}) => {
    return (
        <div className={`inline-block relative ${className}`}>
            <Text
                className={`whitespace-nowrap relative z-[2] px-[4px] ${textColorClassName} ${textSizeClassName}`}
                enablePreWhiteSpace={false}>
                {text}
            </Text>
            <div
                className={`w-full opacity-[10%] dark:opacity-[15%] z-[1] rounded-[10px] absolute ${bgColorClassName} ${bgHeightClassName}`}
            />
        </div>
    )
}

export default TextHighlight
