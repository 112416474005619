import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment, useEffect, useImperativeHandle, useRef, useState} from 'react'
import PortfolioShareContainer from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import useQueryGetUserProfile from '@hook/query/portfolio/useQueryGetUserProfile'
import {useLoginStore} from '@store/LoginStore'
import {PORTFOLIO_REFRESH_INTERVAL} from '@constant/refreshInterval'
import IconClose from '@svg/common/ic_close.svg'

interface IProps {
    onCloseCallback?: () => void
    children?: React.ReactNode
}

const SharePortfolioModal = ({onCloseCallback, children}: IProps, ref: any) => {
    const {t} = useTranslation()
    const [isOpen, setIsOpen] = useState(false)
    const [isFirst, setIsFirst] = useState(false)

    const loginId = useLoginStore(state => state.id)
    const {data, refetch} = useQueryGetUserProfile(loginId, isOpen && PORTFOLIO_REFRESH_INTERVAL)

    useImperativeHandle(ref, () => ({
        show: () => {
            showModal()
        },
        close: () => {
            closeModal()
            setIsFirst(false)
        },
    }))

    const closeModal = () => {
        setIsOpen(false)
        onCloseCallback && onCloseCallback()
    }

    const showModal = () => {
        setIsOpen(true)
        void refetch()
    }

    const getPanelStyle = () => {
        const widthStyle = `w-full max-w-[800px] md:w-full md:max-w-[600px] md:min-w-[320px]`
        const heightStyle = `max-h-[600px] flex-1 md:max-h-full md:flex-1`
        const paddingStyle = ''
        const roundedStyle = 'rounded-[5px] md:rounded-none overflow-hidden'
        const shadowStyle = 'shadow-xl md:shadow-none'

        return `${widthStyle} ${heightStyle} ${paddingStyle} ${roundedStyle} ${shadowStyle}`
    }

    const divRef = useRef<any>()

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                if (divRef?.current) {
                    divRef.current.scrollTop = 0
                }
            }, 100)
        }
    }, [isOpen])

    return (
        <Dialog open={isOpen} as="div" className="relative z-modal" unmount={true} onClose={closeModal}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-[3px]" />
            </Transition.Child>

            <div ref={divRef} className="fixed inset-0 overflow-y-auto" data-cy={'share_portfolio_modal'}>
                <div className={`flex flex-col min-h-full items-center justify-center`}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <Dialog.Panel id={'share_portfolio'} className={`bg-white ${getPanelStyle()}`}>
                            <div className={'relative h-full'}>
                                <div
                                    className={
                                        'absolute top-[20px] right-[20px] z-[1] cursor-pointer flex justify-between items-center md:relative md:top-0 md:right-0 md:w-full md:px-[20px] md:py-[18px]'
                                    }
                                    onClick={() => closeModal()}>
                                    <Text className={'text-h4 text-gray01 hidden md:block'}>
                                        {t('modal.snsShare.title')}
                                    </Text>
                                    <IconClose className={'w-[22px] fill-gray02'} />
                                </div>
                                <PortfolioShareContainer
                                    isFirst={isFirst}
                                    setIsFirst={e => setIsFirst(e)}
                                    profitData={data?.profit_summary}
                                />
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    )
}

export default React.forwardRef(SharePortfolioModal)
